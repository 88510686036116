export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}
export const searchForm = {
	status:undefined,
	rewardType: undefined,
	type: undefined,
	keyword:''
}
export const columns = [
	{
		title: "任务单号",
		width: "14%",
		dataIndex: "code",
	},
	{
		title: "创建人名称",
		width: "5%",
		dataIndex: "createName",
	},
	{
		title: "任务状态",
		width: "4%",
		dataIndex: "status",
		customRender: function (status) {
			switch (status) {
			case 1:
				return '已发布'
			case 2:
				return '已接取'
			case 3:
				return '服务中'
			case 4:
				return '待确认'
			case 5:
				return '已完成'
			case 6:
				return '已评价'
			case 9:
				return '已取消'
			}
		}
	},
	{
		title: "类型",
		width: "4%",
		dataIndex: "type",
		customRender: function (type) {
			switch (type) {
			case 1:
				return '跑腿'
			case 2:
				return '家政'
			case 3:
				return '维修'
			case 4:
				return '家教'
			case 9:
				return '其他'
			}
		}
	},
	{
		title: "备注",
		width: "10%",
		dataIndex: "remarks",
	},
	{
		title: "接单人名称",
		width: "5%",
		dataIndex: "receiverName",
	},
	{
		title: "可接单开始时间",
		width: "10%",
		dataIndex: "readyStartTime",
	},
	{
		title: "可接单结束时间",
		width: "10%",
		dataIndex: "readyEndTime",
	},
	{
		title: "服务人员",
		width: "4%",
		dataIndex: "servicePersonnel",
		customRender: function (servicePersonnel) {
			switch (servicePersonnel) {
			case 1:
				return '住户'
			case 2:
				return '物业'
			}
		}
	},
	{
		title: "联系人",
		width: "4%",
		dataIndex: "contact",
	},
	{
		title: "报酬类型",
		width: "4%",
		dataIndex: "rewardType",
		customRender: function (rewardType) {
			switch (rewardType) {
			case 1:
				return '赏金'
			case 2:
				return '积分'
			}
		}
	},
	{
		title: "报酬数",
		width: "4%",
		dataIndex: "reward",
	},
	{
		title: "创建时间",
		width: "8%",
		dataIndex: "createDate",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const options = {
	rewardType: [
		{
			id: 1, 
			name:'赏金'
		},
		{
			id: 2, 
			name:'积分'
		},
	],
	type: [
		{
			id:1, name:'跑腿'
		},
		{
			id:2, name:'家政'
		},
		{
			id:3, name:'维修'
		},
		{
			id:4, name:'家教'
		},
		{
			id:9, name:'其他'
		},
	]
}