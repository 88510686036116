import httpService from "@/request"

// 任务列表
export function taskList(params) {
	return httpService({
		url: `/user/taskRelease/list`,
		method: 'get',
		params: params,
	})
}
// 置顶
export function topTask(params) {
	return httpService({
		url: `/user/taskRelease/topTask`,
		method: 'get',
		params: params,
	})
}
// 取消
export function cancelTask(params) {
	return httpService({
		url: `/user/taskRelease/cancel`,
		method: 'get',
		params: params,
	})
}
// 撤回
export function withdrawTask(params) {
	return httpService({
		url: `/user/taskRelease/withdraw`,
		method: 'get',
		params: params,
	})
}
// 确认
export function confirmTask(params) {
	return httpService({
		url: `/user/taskRelease/confirm`,
		method: 'get',
		params: params,
	})
}
// 获取任务设置
export function getSetting(params) {
	return httpService({
		url: `/user/settingTaskRelease/getSetting`,
		method: 'get',
		params: params,
	})
}
// 保存任务设置
export function Setting(params) {
	return httpService({
		url: `/user/settingTaskRelease/setting`,
		method: 'post',
		data: params,
	})
}
