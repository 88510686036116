<template>
  <div>
    <div class="cardTitle">
      <a-space size="large">
        <span>任务列表</span>
        <a-radio-group
          v-model="activeName"
          button-style="solid"
          @change="tabsChange"
        >
          <a-radio-button value="0"> 全部 </a-radio-button>
          <a-radio-button value="1"> 已发布 </a-radio-button>
          <a-radio-button value="2"> 已接取 </a-radio-button>
          <a-radio-button value="3"> 服务中 </a-radio-button>
          <a-radio-button value="4"> 待确认 </a-radio-button>
          <a-radio-button value="5"> 已完成 </a-radio-button>
          <a-radio-button value="6"> 已评价 </a-radio-button>
          <a-radio-button value="9"> 已取消 </a-radio-button>
        </a-radio-group>
      </a-space>
    </div>
    <div class="search-box">
      <a-row>
        <a-col :span="20">
          <a-space size="large">
            <a-select placeholder="报酬类型" v-model="searchForm.rewardType" style="width: 200px" >
              <a-select-option v-for="item in options.rewardType" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
            </a-select>
            <a-select placeholder="类型"  v-model="searchForm.type"  style="width: 200px" >
              <a-select-option v-for="item in options.type" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
            </a-select>
            <a-input placeholder="关键字"  v-model="searchForm.keyword"  style="width: 200px" />
            <a-button type="primary" @click='getData'>查 询</a-button>
            <a-button @click='reset'>重 置</a-button>
          </a-space>
        </a-col>
      </a-row>
    </div>
    <div class="main">
      <div style="margin-bottom: 16px">
        <!-- 批量操作 -->
        <a-select
          type="primary"
          v-model="activeAction"
          :disabled="!hasSelected"
          :loading="loading"
          style="width: 120px"
          @change="Actions"
          placeholder="请选择操作"
        >
          批量
          <a-select-option v-for="item in ActionsList" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
        <span style="margin-left: 8px">
          <template v-if="hasSelected">
            {{ `已选择 ${selectedRowKeys.length} 条` }}
          </template>
        </span>
      </div>
      <!-- 表格 -->
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="pagination"
        :scroll="{ x: 2500 }"
        @change="handleTableChange"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: selectionChoosed,
        }"
        :row-key="
          (record, index) => {
            return index;
          }
        "
      >
        <span slot="action" slot-scope="text, row">
          <a-space>
            <a class="ant-dropdown-link" v-if='row.status===1' @click="top(row)">推荐置顶</a>
            <a class="ant-dropdown-link" v-if='row.status===2||row.status===3' @click="withdraw(row)">撤回大厅</a>
            <a class="ant-dropdown-link" v-if='row.status===4' @click="confirm(row)">确认完成</a>
            <a class="ant-dropdown-link" @click="cancel(row)" v-if='row.status<4'>取消</a>
          </a-space>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { pagination, searchForm, columns, options } from "./depend/config";
import {
	taskList,
	topTask,
	cancelTask,
	withdrawTask,
	confirmTask,
} from "@/api/operation/task";
export default {
	data() {
		return {
			activeName: "0",
			pagination,
			searchForm,
			activeAction: undefined,
			loading: false,
			// 选择的index
			selectedRowKeys: [],
			tableChoosed: [],
			//   列
			columns,options,
			//   数据
			tableData: [],
			ActionsList: [],
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		async getData() {
			let res = await taskList({
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
				...this.searchForm
			});
			this.tableData = res.data.rows;
			this.pagination.total = res.data.total;
		},
		tabsChange(e) {
			if(e.target.value === '0'){
				this.searchForm.status = undefined
				this.pagination.current = 1;
				this.pagination.pageSize = 10;
				this.getData();
			} else {
				this.pagination.current = 1;
				this.pagination.pageSize = 10;
				this.searchForm.status = e.target.value;
				this.getData();
			}
		},
		handleTableChange(pagination) {
			this.pagination.current = pagination.current;
			this.pagination.pageSize = pagination.pageSize;
			this.getData();
		},
		Actions(data) {
			this.activeAction = undefined;
		},
		reset(){
			this.searchForm = {
				rewardType: undefined,
				type: undefined,
				keyword:''
			}
			this.getData()
		},
		selectionChoosed(data) {
			this.tableChoosed = data;
		},
		async top(data) {
			let res = await topTask({ taskId: data.id });
			if (res.code === 200) {
				this.$message.success(res.msg);
				this.getData();
			} else {
				this.$message.error(res.msg);
			}
		},
		async cancel(data) {
			let res = await cancelTask({ taskId: data.id });
			if (res.code === 200) {
				this.$message.success(res.msg);
				this.getData();
			} else {
				this.$message.error(res.msg);
			}
		},
		async confirm(data) {
			let res = await confirmTask({ taskId: data.id });
			if (res.code === 200) {
				this.$message.success(res.msg);
				this.getData();
			} else {
				this.$message.error(res.msg);
			}
		},
		async withdraw(data) {
			let res = await withdrawTask({ taskId: data.id });
			if (res.code === 200) {
				this.$message.success(res.msg);
				this.getData();
			} else {
				this.$message.error(res.msg);
			}
		},
	},
	computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
};
</script>

<style lang="less" scoped>
</style>